















































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

import * as types from "@/store/types";

@Component({
    components: {
        HelpDialog: () => import("@/components/dialogs/HelpDialog.vue")
    },
    computed: {
        ...mapGetters({
            drawer: types.APP_DRAWER
        })
    },
    methods: {
        ...mapMutations({
            setDrawer: types.MUTATE_APP_DRAWER
        })
    }
})
export default class AppBar extends Vue {
    setDrawer!: (state: boolean) => void;
    drawer!: boolean;

    dialog = false;
    helperComponentHTML = "";

    /**
     * Methods
     */
    getRouteName() {
        return this.$route.name;
    }

    onHelpBtnClick() {
        this.dialog = true;
        this.helperComponentHTML = this.$t(
            "help." + this.$route.name
        ).toString();
    }

    /**
     * Find if current view has its help translation text
     */
    get existHelperText() {
        const currentView = this.$route.name;
        const transKey = "help." + currentView;

        return this.$i18n.te(transKey);
    }
}
